// TODO: Update to use MaterialUI

import { useEffect, useState } from 'react'
import {
    NodeService,
    NodeStatusFilter,
    NodeOrder,
    QueryParams,
} from '../libs/ambient_api/NodeService'
// import { ClusterService } from '../libs/ambient_api/ClusterService'
import { useNavigate } from 'react-router'
import { HttpError } from '../types/Errors'
import {
    Node,
    Cluster,
    DeviceAuthResponse,
    NodePanelData,
} from '../types/TechnicalTypes'
import AddNodePanel from '../components/AddNodePanel'
import { HomeButton } from '../components/HomeButton'
import {
    AppBar,
    Button,
    ButtonGroup,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    Paper,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Checkbox,
    ThemeProvider,
    TextField,
    setRef,
    Icon,
    Portal,
    Modal,
    Tab,
    Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddModeratorIcon from '@mui/icons-material/AddModerator'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import AddBoxIcon from '@mui/icons-material/AddBox'
import EditIcon from '@mui/icons-material/Edit'
import TopToolBar from '../components/AppToolBar'
import RefreshIcon from '@mui/icons-material/Refresh'
import AddIcon from '@mui/icons-material/Add'
import { formatDistanceToNow, set } from 'date-fns'
import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/Download'
import WifiIcon from '@mui/icons-material/Wifi'
import { useTheme } from '../ThemeContext'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { CodeBlock, CopyBlock } from 'react-code-blocks'
import LaunchIcon from '@mui/icons-material/Launch'
import TableFooter from '@mui/material/TableFooter'
import EditNodePanel from '../components/EditNodePanel'
import TerminalIcon from '@mui/icons-material/Terminal'
import LaunchTerminalPanel from '../components/LaunchTerminalPanel'
import UserService from '../libs/ambient_api/UserService'
import { User } from '../types/User'

const NodesPage: React.FC = () => {
    const navigate = useNavigate()
    const [nodes, setNodes] = useState<Node[]>([])
    // const [clusters, setClusters] = useState<Cluster[]>([])
    const [dataReady, setDataReady] = useState<boolean>(false)
    const [rows, setRows] = useState<any[][]>([])
    const [showAddNodePanel, setShowAddNodePanel] = useState<boolean>(false)
    const [refresh, setRefresh] = useState<boolean>(false)
    const [showWaitingScreen, setShowWaitingScreen] = useState<boolean>(false)
    const [showNewAuthPanel, setShowNewAuthPanel] = useState<boolean>(false)
    const [newAuthNode, setNewAuthNode] = useState<Node | null>(null)
    const [token, setToken] = useState<string>('')
    const [authNodeId, setAuthNodeId] = useState<number>(0)
    const [infoPanelData, setInfoPanelData] = useState<NodePanelData>({
        all: 0,
        active: 0,
        inactive: 0,
        error: 0,
        live: 0,
    })

    const theme = useTheme()
    const [queryParams, setQueryParams] = useState<QueryParams>({
        status: null,
        sort_by: 'name',
        order: NodeOrder.asc,
        name_starts_with: '',
    })
    const [search, setSearch] = useState<string>('')
    const [showEditNodePanel, setShowEditNodePanel] = useState<boolean>(false)
    const [showLaunchTerminalPanel, setShowLaunchTerminalPanel] =
        useState<boolean>(false)
    const [user, setUser] = useState<User | null>(null)
    const [selectedNode, setSelectedNode] = useState<Node | null>(null)

    // auth useEffect
    useEffect(() => {
        const _token = localStorage.getItem('access_token')
        const fetchUser = async () => {
            if (_token) {
                const userService = new UserService(_token)
                const user = await userService.whoami()
                setUser(user)
            }
        }
        if (_token) {
            setToken(_token)
            fetchUser()
        } else {
            navigate('/home')
        }
        setRefresh(!refresh)
        setRefresh(!refresh)
    }, [navigate])

    const handleAddAuth = async (rowIndex: number) => {
        // console.log('Adding auth for row ' + rowIndex)
        // get node ID from rowIndex
        const nodeId = nodes[rowIndex].id
        setAuthNodeId(nodeId)
        // console.log('Node ID:', nodeId)

        // request auth from backend
        const token = localStorage.getItem('access_token')
        if (!token) {
            // console.log('No token')
            navigate('/home')
        }
        const nodeService = new NodeService()
        try {
            const nodeWithNewAuth = await nodeService.requestNewDeviceAuth(
                token!,
                nodeId
            )
            // console.log('Node with new auth:', nodeWithNewAuth)
            setNewAuthNode(nodeWithNewAuth)
            setShowNewAuthPanel(true)
        } catch (error) {
            // console.log('Error: ' + error)
            if (error instanceof HttpError) {
                switch (error.statusCode) {
                    case 401:
                        navigate('/home')
                        break
                    case 404:
                        break
                    default:
                        break
                }
            } else {
                // console.log('Error: ' + error)
            }
        }
    }

    useEffect(() => {
        console.log('Node Authorization' + JSON.stringify(newAuthNode))
    }, [newAuthNode])

    const code_props = {
        code: `ambientctl auth \\\n\t-d ${newAuthNode?.authorization?.device_code} \\\n\t-n ${authNodeId}`,
        language: 'bash',
        showLineNumbers: true,
    }

    const newAuthPanel = (
        // Refactor to use MaterialUI
        <Modal
            open={showNewAuthPanel}
            onClose={() => setShowNewAuthPanel(false)}
            aria-labelledby="add-node-modal"
            aria-describedby="add-node-modal"
        >
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ font: 'sans' }}
            >
                <Paper
                    style={{
                        width: '600px',
                        height: '500px',
                        padding: '20px',
                        margin: '20px',
                    }}
                >
                    <Grid item margin={2} marginLeft={10}>
                        <Typography variant="h4">
                            New Authorization Code
                        </Typography>
                    </Grid>
                    {newAuthNode ? (
                        <Box>
                            <Grid item marginBottom={2} marginLeft={2}>
                                <Typography variant="h6">
                                    Follow this link to authorize your device:
                                </Typography>
                            </Grid>
                            <Grid>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href={
                                        newAuthNode?.authorization
                                            ?.verification_uri_complete
                                    }
                                    rel="noreferrer"
                                    endIcon={<LaunchIcon />}
                                    style={{
                                        margin: '10px',
                                        marginLeft: '170px',
                                        marginBottom: '40px',
                                    }}
                                >
                                    Authorize Device
                                </Button>
                            </Grid>
                            <Grid item paddingLeft={2}>
                                <Typography variant="body1">
                                    After you authorize your device, run the
                                    following command to complete the process:
                                </Typography>
                            </Grid>
                            <Grid item margin={2}>
                                <CopyBlock
                                    text={code_props.code}
                                    language={code_props.language}
                                    showLineNumbers={code_props.showLineNumbers}
                                    codeBlock
                                />
                            </Grid>
                        </Box>
                    ) : null}
                    <Grid item margin={2} marginTop={5} marginLeft={28}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                setShowNewAuthPanel(false)
                            }}
                        >
                            Close
                        </Button>
                    </Grid>
                </Paper>
            </Grid>
        </Modal>
    )

    // get data useEffect to get nodes
    useEffect(() => {
        const fetchAllData = async () => {
            const token = localStorage.getItem('access_token')
            // const clusterService = new ClusterService(token!)
            const nodeService = new NodeService()

            try {
                nodeService
                    .getNodes(
                        token!,
                        queryParams.sort_by,
                        queryParams.order,
                        queryParams.status,
                        queryParams.name_starts_with
                    )
                    .then((nodes) => {
                        setNodes(nodes)
                        const newRows = nodes.map((node) => {
                            return [
                                node.id,
                                node.name,
                                node.status,
                                node.last_seen,
                                node.architecture,
                            ]
                        })
                        setRows(newRows)
                    })
            } catch (error) {
                // console.log('Error: ' + error)
                if (error instanceof HttpError) {
                    switch (error.statusCode) {
                        case 401:
                            navigate('/home')
                            break
                        case 404:
                            break
                        default:
                            break
                    }
                } else {
                    // console.log('Error: ' + error)
                }
            }
        }
        setShowWaitingScreen(true)
        fetchAllData()
        setShowWaitingScreen(false)
        // setDataReady(false)
        setDataReady(true)
    }, [refresh, token, queryParams])

    const loadingScreen = (
        <div className="flex flex-col items-center justify-center h-screen w-screen bg-black opacity-70">
            <h1 className="text-4xl font-bold">Loading...</h1>
        </div>
    )

    const refreshButton = (
        <Button
            onClick={() => {
                setRefresh(!refresh)
            }}
            style={{ marginLeft: '2px' }}
        >
            <RefreshIcon color="primary" />
        </Button>
    )

    const addNodeButton = (
        // <IconButton
        //     onClick={() => {
        //         setShowAddNodePanel(true)
        //     }}
        // >
        //     <AddIcon color="primary" />
        // </IconButton>
        <Button
            variant="contained"
            color="primary"
            onClick={() => {
                setShowAddNodePanel(true)
            }}
            startIcon={<AddIcon />}
            disableElevation
            style={{ margin: '10px' }}
        >
            Add Node
        </Button>
    )

    const handleDelete = async (rowIndex: number) => {
        // console.log('Deleting row ' + rowIndex)
        const nodeId = nodes[rowIndex].id
        const nodeService = new NodeService()
        try {
            await nodeService.deleteNode(token!, nodeId)
            setRefresh(!refresh)
        } catch (error) {
            // console.log('Error: ' + error)
            if (error instanceof HttpError) {
                switch (error.statusCode) {
                    case 401:
                        navigate('/home')
                        break
                    case 404:
                        break
                    default:
                        break
                }
            } else {
                // console.log('Error: ' + error)
            }
        }
    }

    // get the panel data using the getNodesPanelData method
    useEffect(() => {
        const fetchPanelData = async () => {
            const token = localStorage.getItem('access_token')
            const nodeService = new NodeService()
            try {
                const panelData = await nodeService.getNodesPanelData(token!)
                setInfoPanelData(panelData)
            } catch (error) {
                // console.log('Error: ' + error)
                if (error instanceof HttpError) {
                    switch (error.statusCode) {
                        case 401:
                            navigate('/home')
                            break
                        case 404:
                            break
                        default:
                            break
                    }
                } else {
                    // console.log('Error: ' + error)
                }
            }
        }
        fetchPanelData()
    }, [refresh, token])

    const handleStatusFilter = (status: NodeStatusFilter | null) => {
        setQueryParams({
            ...queryParams,
            status: status,
        })
        setRefresh(!refresh)
    }

    const SearchBar = (
        <Paper
            style={{
                marginTop: '40px',
                marginBottom: '2px',
            }}
        >
            <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignContent={'center'}
            >
                <Grid item marginLeft={2} marginBottom={2}>
                    <TextField
                        id="search"
                        label="Search"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={() => {
                                        setQueryParams({
                                            ...queryParams,
                                            name_starts_with: search,
                                        })
                                        setRefresh(!refresh)
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </Grid>
                <Grid item marginRight={2} marginBottom={2}>
                    <ButtonGroup>
                        <Button
                            variant={
                                queryParams.status == null
                                    ? 'contained'
                                    : 'outlined'
                            }
                            onClick={() => handleStatusFilter(null)}
                        >
                            All
                        </Button>
                        <Button
                            variant={
                                queryParams.status == NodeStatusFilter.active
                                    ? 'contained'
                                    : 'outlined'
                            }
                            onClick={() =>
                                handleStatusFilter(NodeStatusFilter.active)
                            }
                        >
                            Active
                        </Button>
                        <Button
                            variant={
                                queryParams.status == NodeStatusFilter.pending
                                    ? 'contained'
                                    : 'outlined'
                            }
                            onClick={() =>
                                setQueryParams({
                                    ...queryParams,
                                    status: NodeStatusFilter.pending,
                                })
                            }
                        >
                            Pending
                        </Button>
                        <Button
                            variant={
                                queryParams.status == NodeStatusFilter.error
                                    ? 'contained'
                                    : 'outlined'
                            }
                            onClick={() =>
                                setQueryParams({
                                    ...queryParams,
                                    status: NodeStatusFilter.error,
                                })
                            }
                        >
                            Error
                        </Button>
                    </ButtonGroup>
                    <Button
                        variant="contained"
                        endIcon={<DownloadIcon />}
                        style={{ marginLeft: '10px' }}
                        disabled
                    >
                        Export
                    </Button>
                    {refreshButton}
                </Grid>
            </Grid>
        </Paper>
    )

    const InfoPanel = (
        <Box sx={{ p: 2 }}>
            <Grid container spacing={2} justifyContent="space-around">
                <Grid item xs={12} sm={6} md={2}>
                    <Box border={1} borderRadius={2} textAlign="center" p={2}>
                        <Typography variant="h4">
                            {infoPanelData.all}
                        </Typography>
                        <Typography variant="subtitle1">Nodes</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Box border={1} borderRadius={2} textAlign="center" p={2}>
                        <Typography
                            variant="h4"
                            color={
                                infoPanelData.live > 0
                                    ? 'secondary'
                                    : 'primary.text'
                            }
                        >
                            {infoPanelData.live}
                        </Typography>
                        <Typography variant="subtitle1">
                            <WifiIcon
                                color="secondary"
                                style={{
                                    marginRight: '5px',
                                    marginBottom: '-5px',
                                }}
                            />
                            Live
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Box border={1} borderRadius={2} textAlign="center" p={2}>
                        <Typography variant="h4" color="green">
                            {infoPanelData.active}
                        </Typography>
                        <Typography variant="subtitle1">Active</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Box border={1} borderRadius={2} textAlign="center" p={2}>
                        <Typography
                            variant="h4"
                            color={
                                infoPanelData.inactive > 0
                                    ? 'text.secondary'
                                    : 'green'
                            }
                        >
                            {infoPanelData.inactive}
                        </Typography>
                        <Typography variant="subtitle1">Inactive</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Box border={1} borderRadius={2} textAlign="center" p={2}>
                        <Typography variant="h4">
                            {infoPanelData.error}
                        </Typography>
                        <Typography variant="subtitle1">Error</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

    // handle sorter click
    const handleSorterClick = (field: string) => {
        setQueryParams({
            ...queryParams,
            sort_by: field,
            order:
                queryParams.sort_by === field
                    ? queryParams.order === NodeOrder.asc
                        ? NodeOrder.desc
                        : NodeOrder.asc
                    : NodeOrder.asc,
        })
        setRefresh(!refresh)
    }

    return (
        <div className="font-sans">
            <TopToolBar />
            {showEditNodePanel ? (
                <EditNodePanel
                    onClose={() => setShowEditNodePanel(false)}
                    node={nodes[0]}
                    token={token}
                />
            ) : null}
            {showLaunchTerminalPanel ? (
                <LaunchTerminalPanel
                    open={showLaunchTerminalPanel}
                    onClose={() => setShowLaunchTerminalPanel(false)}
                    token={token}
                    nodeIds={[nodes[0].id]}
                    username={user!.username!}
                    hostname={selectedNode!.name}
                />
            ) : null}
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={10}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="right"
                        alignItems="right"
                    >
                        {addNodeButton}
                    </Grid>
                </Grid>
                <Grid item xs={10}>
                    {InfoPanel}
                </Grid>
                <Grid item xs={10}>
                    {SearchBar}
                </Grid>
                {/* {dataReady ? ( */}
                <Grid item xs={10}>
                    <Paper>
                        <Table
                        // style={{
                        //     outline: theme.themeMode === 'dark' ? '1px solid white' : '1px solid black',
                        // }}
                        >
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell padding="checkbox">
                                            <Checkbox />
                                        </TableCell> */}
                                    <TableCell>
                                        <Grid
                                            container
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                        >
                                            ID
                                            <IconButton
                                                style={{
                                                    marginTop: '-8px',
                                                }}
                                                onClick={() =>
                                                    handleSorterClick('id')
                                                }
                                            >
                                                <SwapVertIcon color="secondary" />
                                            </IconButton>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid
                                            container
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                        >
                                            Name
                                            <IconButton
                                                style={{
                                                    marginTop: '-8px',
                                                }}
                                                onClick={() =>
                                                    handleSorterClick('name')
                                                }
                                            >
                                                <SortByAlphaIcon color="secondary" />
                                            </IconButton>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid
                                            container
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                        >
                                            Status
                                            <IconButton
                                                style={{
                                                    marginTop: '-8px',
                                                }}
                                                onClick={() => {
                                                    handleSorterClick('status')
                                                }}
                                            >
                                                <SwapVertIcon color="secondary" />
                                            </IconButton>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid
                                            container
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                        >
                                            Last Seen
                                            <IconButton
                                                style={{
                                                    marginTop: '-8px',
                                                }}
                                                onClick={() =>
                                                    handleSorterClick(
                                                        'last_seen'
                                                    )
                                                }
                                            >
                                                <SwapVertIcon color="secondary" />
                                            </IconButton>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid
                                            container
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                        >
                                            Architecture
                                            <IconButton
                                                style={{
                                                    marginTop: '-8px',
                                                }}
                                                onClick={() =>
                                                    handleSorterClick(
                                                        'architecture'
                                                    )
                                                }
                                            >
                                                <SwapVertIcon color="secondary" />
                                            </IconButton>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex}>
                                                {
                                                    /* {cell} instead of this we want to use humanFriendlyDate(cell) but only if it's time*/
                                                    typeof cell === 'string' &&
                                                    cell.includes('T')
                                                        ? humanFriendlyDate(
                                                              cell
                                                          )
                                                        : cell
                                                }
                                            </TableCell>
                                        ))}
                                        <TableCell>
                                            <IconButton
                                                onClick={() =>
                                                    handleAddAuth(rowIndex)
                                                }
                                            >
                                                <Tooltip
                                                    title="Authorize Node"
                                                    arrow
                                                >
                                                    <AddModeratorIcon color="secondary" />
                                                </Tooltip>
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    // console.log('Redirecting to node page')
                                                    navigate(
                                                        `/nodes/${nodes[rowIndex].id}`
                                                    )
                                                }}
                                            >
                                                <Tooltip
                                                    title="View Node"
                                                    arrow
                                                >
                                                    <ArrowOutwardIcon color="secondary" />
                                                </Tooltip>
                                            </IconButton>
                                            {/* <IconButton>
                                                <AddBoxIcon color="secondary" />
                                            </IconButton> */}
                                            <IconButton
                                                onClick={() => {
                                                    setShowEditNodePanel(true)
                                                }}
                                            >
                                                <Tooltip
                                                    title="Edit Node"
                                                    arrow
                                                >
                                                    <EditIcon color="secondary" />
                                                </Tooltip>
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    setShowLaunchTerminalPanel(
                                                        true
                                                    )
                                                    setSelectedNode(
                                                        nodes[rowIndex]
                                                    )
                                                }}
                                            >
                                                <Tooltip
                                                    title="Launch Terminal"
                                                    arrow
                                                >
                                                    <TerminalIcon color="secondary" />
                                                </Tooltip>
                                            </IconButton>
                                            <IconButton
                                                onClick={() =>
                                                    handleDelete(rowIndex)
                                                }
                                            >
                                                <Tooltip
                                                    title="Delete Node"
                                                    arrow
                                                >
                                                    <DeleteIcon color="primary" />
                                                </Tooltip>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        count={rows.length}
                                        onPageChange={() =>
                                            console.log('Page change')
                                        }
                                        page={0}
                                        rowsPerPage={10}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Paper>
                </Grid>
                {/* ) : null} */}
            </Grid>
            {showAddNodePanel ? (
                <AddNodePanel
                    onClose={() => setShowAddNodePanel(false)}
                    token={token}
                />
            ) : null}
            {showWaitingScreen ? loadingScreen : null}
            {showNewAuthPanel ? newAuthPanel : null}
        </div>
    )
}

export default NodesPage

const humanFriendlyDate = (date: string) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true })
}
