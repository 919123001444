import React, { FC, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ApplicationService } from '../libs/ambient_api/ApplicationService'
import { NodeService } from '../libs/ambient_api/NodeService'
import { CreateServiceRequest } from '../types/ApiRequests'
import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Modal,
    TextField,
    Typography,
    Grid,
    Paper,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { SelectChangeEvent } from '@mui/material'
import { Service } from '../types/TechnicalTypes'

interface EditAppPanelProps {
    onClose: () => void
    application: Service
    token: string
}

const EditAppPanel: FC<EditAppPanelProps> = ({
    onClose,
    application,
    token,
}) => {
    const theme = useTheme()
    const [name, setName] = useState<string>(application.name)
    const [description, setDescription] = useState<string>(
        application.description!
    )
    const [image, setImage] = useState<string>(application.image)
    const [ports, setPorts] = useState<string[]>(application.ports)
    const [tags, setTags] = useState<string[]>(application.tags)
    const [replicas, setReplicas] = useState<number>(application.replicas!)

    const handleSave = async () => {
        const applicationService = new ApplicationService(token)
        const updatedApplication = {
            id: application.id,
            name: name,
            description: description,
            image: image,
            ports: ports,
            tags: tags,
            replicas: replicas,
        }
        await applicationService.patchApplication(
            application.id,
            updatedApplication
        )
        onClose()
    }

    function parseListNumber(input: string): number[] {
        console.log('AddApplicationPanel parseListNumber input:', input)
        if (!input) {
            console.log(
                'AddApplicationPanel parseListNumber returning empty array'
            )
            return []
        }
        try {
            console.log(
                'AddApplicationPanel parseListNumber returning parsed array'
            )
            return input.split(',').map((s) => parseInt(s.trim()))
        } catch (e) {
            console.log('AddApplicationPanel parseListNumber error:', e)
            console.log(
                'AddApplicationPanel parseListNumber returning empty array'
            )
            return []
        }
    }

    function parseListString(input: string): string[] {
        console.log('AddApplicationPanel parseListString input:', input)
        if (!input) {
            console.log(
                'AddApplicationPanel parseListString returning empty array'
            )
            return []
        }
        try {
            console.log(
                'AddApplicationPanel parseListString returning parsed array'
            )
            return input.split(',').map((s) => s.trim())
        } catch (e) {
            console.log('AddApplicationPanel parseListString error:', e)
            console.log(
                'AddApplicationPanel parseListString returning empty array'
            )
            return []
        }
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="edit-node-panel"
            aria-describedby="edit-node-panel"
        >
            <Grid
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: theme.palette.background.paper,
                    padding: '20px',
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[5],
                }}
                alignContent={'center'}
                alignItems={'center'}
                justifyContent={'center'}
                direction={'column'}
            >
                <Typography
                    variant="h6"
                    style={{ padding: '10px', paddingBottom: '30px' }}
                >
                    Edit Application
                </Typography>
                <Paper
                    style={{
                        padding: '20px',
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <Grid
                        container
                        direction={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <div style={{ padding: '10px' }}>
                            <TextField
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{ marginBottom: theme.spacing(2) }}
                            />
                        </div>
                        <div style={{ padding: '10px' }}>
                            <TextField
                                label="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                style={{ marginBottom: theme.spacing(2) }}
                            />
                        </div>
                        <div style={{ padding: '10px' }}>
                            <TextField
                                label="Image"
                                value={image}
                                onChange={(e) => setImage(e.target.value)}
                                style={{ marginBottom: theme.spacing(2) }}
                            />
                        </div>
                        <div style={{ padding: '10px' }}>
                            <TextField
                                label="Ports"
                                value={ports}
                                onChange={(e) =>
                                    setPorts(parseListString(e.target.value))
                                }
                                style={{ marginBottom: theme.spacing(2) }}
                            />
                        </div>
                        <div style={{ padding: '10px' }}>
                            <TextField
                                label="Tags"
                                value={tags}
                                onChange={(e) =>
                                    setTags(parseListString(e.target.value))
                                }
                                style={{ marginBottom: theme.spacing(2) }}
                            />
                        </div>
                        <div style={{ padding: '10px' }}>
                            <TextField
                                label="Replicas"
                                value={replicas}
                                onChange={(e) =>
                                    setReplicas(parseInt(e.target.value))
                                }
                                style={{ marginBottom: theme.spacing(2) }}
                            />
                        </div>
                    </Grid>
                </Paper>
                <Grid
                    container
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        style={{ marginTop: theme.spacing(2) }}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default EditAppPanel
